.news_detail{
    background-color: #fff;
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    min-height: 80vh;
    padding: 0 150px;
    .title{
        font-size: 26px;
        font-weight: bold;
        color: #12171F;
        text-align: center;
        padding-top:30px;

    }
    .time{
        font-size: 14px;
        color: #93979D;
        padding-top: 11px;
        padding-bottom: 30px;
        border-bottom: 1px solid #EBEDF0;
        text-align: center;
    }
    .content{
        padding-top: 30px;
        img{
            width: 50%;
        }
    }
}
.news{
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 10px;
    .title{
        height: 56px;
        width: 100%;
        font-size: 18px;
        color: #000000;
        line-height: 56px;
        padding-left: 20px;
        background-color: #fff;
        border-bottom: 1px solid #EBEDF0;
    }
    .news_list{
        background-color: #fff;
        margin-bottom: 10px;
        .news_item{
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            align-items: center;
            border-bottom: 1px solid #EBEDF0;
            margin:0 30px;
            .item_con{
                width: 1040px;
                .h1{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    .name{
                        font-size: 16px;
                        font-weight: bold;
                        color: #12171F;
                        width: 900px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .time{
                        font-size: 16px;
                        font-weight: bold;
                        color: #C2C6CE;
                    }
                }
                .h1_a::after {
                    content: '';
                    width: 7px;
                    height: 7px;
                    background: #F5222D;
                    border-radius: 50%;
                    position: absolute;
                    left: -15px;
                }
                .h4{
                    margin-top: 10px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .item_btn{
                display: flex;
                align-items: center;
                img{
                    width: 18px;
                    height: 18px;
                }
                p{
                    font-size: 14px;
                    color: #93979D;
                    margin-left: 4px;
                }
            }
        }
    }
   
}