.home_content{
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;

    .home_left{
        background-color: #fff;
        width: 160px;
        height: 100%;
        // max-height: calc(100vh - 540px);
        // overflow: hidden;
        // overflow-y: auto;
        .home_left_item{
            width: 160px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            user-select:none;
            font-size: 16px;
            color: #12171F;
        }
        .active{
            background: #006E80;
            color: #FFFFFF;
        }
    }
   
    .home_right{
        // max-height: calc(100vh - 540px);
        // margin-left: 20px;
        // overflow: hidden;
        // overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        .home_right_item{
            margin-right: 20px;
            margin-bottom: 20px;
        }
        .home_right_item:nth-child(4n) {
            margin-right: 0;
        }
        .card{
            width: 285px !important;
            height: 265px !important;
            .card_img{
                width: 100%;
                height: 178px;
            }
        }
    }
}
.pagination{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
}
// .home_tab_list_top::-webkit-scrollbar {
//     display: none; /* Chrome Safari */
//   }   
.home_tab_list_top::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
  }
  .home_tab_list_top::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: rgba(0,0,0,.2);
  }
  .home_tab_list_top::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
    border-radius: 10px;
    background: rgba(0,0,0,.02);
  }


  .home_tab_list_bottom::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
  }
  .home_tab_list_bottom::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: rgba(0,0,0,.2);
  }
  .home_tab_list_bottom::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
    border-radius: 10px;
    background: rgba(0,0,0,.02);
  }
.home_tab_list{
    width: 100%;
    height: 126px;
    background-color: #fff;
    .home_tab_list_top{
        width: 1200px;
        // height: 57px;
       
        margin: 0 auto;
        
        
        overflow: hidden;
        overflow-x: auto;
        padding-bottom: 5px;
        padding-top: 24px;
        .home_tab_list_box{
            border-bottom: 1px solid #F5F7FA;
            display: flex;
            align-items: center;
            padding-bottom: 18px;
        }
        .home_tab_list_top_item{
            padding: 0 10px;
            margin-right: 10px;
            font-size: 18px;
            color: #323233;
            line-height: 20px;
            position: relative;
            white-space: nowrap;
            i{
                position: absolute;
                width: 60px;
                height: 2px;
                background: #006E80;
                bottom: -18px;
                left: 50%;
                margin-left: -30px;
            }
            i:after {
                content: "";
                border: 6px solid;
                border-color: #006E80 transparent transparent transparent;
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .active1{
            color: #006E80;
            font-weight: bold;
        }
    }
    .home_tab_list_bottom{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        padding-top: 8px;
        overflow-x: auto;
        padding-bottom: 5px;
        .home_tab_list_bottom_item{
            white-space: nowrap;
            padding: 8px 16px;
            font-size: 14px;
            color: #323233;
            background: #F7F8FA;
            border-radius: 16px;
            margin-right: 16px;
        }
        .active2{
            background: rgba(0, 110, 128, .1);
            color: #006E80;
        }
    }
}