.banner{
    min-width: 1200px;
    .banner_item{
        min-width: 1200px;
        height: 350px;
        img{
          object-fit: cover;
          
        }
    }
  .ant-carousel .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius:50%;
  }
  .ant-carousel .slick-dots li.slick-active{
    width: 15px;
  }
}
