/*---清除默认样式---*/
@charset "utf-8";
/*base*/
* {
  box-sizing:border-box;
  -webkit-text-size-adjust:none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
html, body, h1, h2, h3, h4, h5,h6, input, button,select,label, form,textarea, p, span, a, div, ol, ul, li, dl, dt, dd, table, tbody, tfoot, thead, tr, th, td, canvas,i,img {
  margin: 0;
  padding: 0;
  border: none;
  font-style: normal;
  font-weight: normal;

}
body {
  font-family:"Microsoft Yahei",STXihei,hei;
  outline: none;
  color:#333;
  position: relative;
}
input,select,button,textarea {
  font-family:"Hiragino Sans GB","Microsoft Yahei";
  outline: none;
  border:0 none;
  background: #fff;
}
input[type="submit"],input[type="reset"],input[type="button"],input[type="text"],button,textarea{
  -webkit-appearance: none;
  border-radius: 0;
  appearance:none;
  -moz-appearance:none;
}
input:focus,textarea:focus{
  outline:none;
}
input[type="text"]{
  vertical-align: middle;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}
/* body {
	font-size:16px;
} */
a {
  text-decoration:none;
}
button {
  cursor: pointer;
}
ul {
  list-style:none;
}
img{
  border: 0 none;
  outline: none;
}
table {
  border-collapse:collapse;
  border-spacing:0;
}
em,i {
  font-style:normal;
}
input,button,textarea,select,optgroup,option {
  font-family:inherit;
  font-size:inherit;
  font-style:inherit;
  font-weight:inherit;
}
input,button,textarea,select {
  *font-size:100%;
}
