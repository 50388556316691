.card{
    width: 240px;
    height: 248px;
    background: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    .card_info{
        padding: 10px;
        .card_top{
            display: flex;
            justify-content: space-between;
            .name{
                font-size: 16px;
                font-weight: bold;
                color: #12171F;
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .time{
                font-size: 12px;
                color: #93979D;
            }
        }
        .info{
            font-size: 14px;
            color: #12171F;
            line-height: 20px;
            overflow: hidden;             /*超出内容隐藏*/
            text-overflow: ellipsis;      /*超出内容为省略号*/
            display: -webkit-box;         /*内容不换行*/
            -webkit-line-clamp: 2;        /*超出几行*/
            -webkit-box-orient: vertical; /*从上到下垂直排列子元素*/
            margin-top: 10px;
        }
    }
    .card_img{
        width: 240px;
        height: 151px;
        position: relative;
        img{
            object-fit: cover;
        }
        .corner{
            width: 56px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 12px;
            color: #FFFFFF;
            text-align: center;
            line-height: 20px;
            // background: url('~images/logo.png') no-repeat;
        }
        .charge {
            background: url('~images/charge.png') no-repeat;
            background-size:100% 22px;
        }
        .free {
            background: url('~images/free.png') no-repeat;
            background-size:100% 22px;
        }
    }
}