html,body{
    width: 100%;
    height: 100%;
    background-color: #EFF0F2;
    // overflow-x: hidden;
    
}
body{
    overflow-x: hidden;
   
}
img{
    width: 100%;
    height: 100%;
}
.cursor{
    cursor: pointer;
    user-select:none
}
#root{
    height: 100%;
}

.bottom_more{
    width: 120px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #666;
    position: relative;
    left: 50%;
    margin-left: -60px;
}