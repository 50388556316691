.pay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    .pay_con{
        width: 720px;
        height: 538px;
        background: #FFFFFF;
        border-radius: 4px;
        padding: 30px;
        position: relative;
        .warning{
            height: 48px;
            background: rgba(0,110,128,0.1);
            border-radius: 2px;
            border: 1px solid #006E80;
            display: flex;
            align-items: center;
            padding-left: 16px;
            margin-top: 20px;
            img{
                width: 16px;
                height: 16px;
            }
            p{
                margin-left: 8px;
                font-size: 14px;
                color: #12171F;
            }
        }
        .pay_btn{
            position: absolute;
            bottom: 30px;
            right: 30px;
            display: flex;
            .pay1{
                width: 96px;
                height: 40px;
                border-radius: 2px;
                border: 1px solid #DCDEE0;
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                color: #12171F;
                
            }
            .pay2{
                width: 96px;
                height: 40px;
                background: #006E80;
                border-radius: 2px; 
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                color: #FFFFFF;
                margin-left: 8px;
            }
        }
        .pay_code{
            display: flex;
            flex-direction: column;
            margin-left: 86px;
            margin-top: 8px;
            width: 120px;
            img{
                width: 120px;
                height: 120px;
            }
            p{
                text-align: center;
                font-size: 14px;
                color: #12171F;
                margin-top: 8px;
            }
        }
        .pay_method{
            display: flex;
            align-items: center;
            margin-top: 10px;
            .p{
                font-size: 14px;
                color: #12171F;
                margin-right: 30px;
            }
        }
        .method_item{
            display: flex;
            width: 119px;
            height: 40px;
            border: 1px solid #DCDEE0;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            img{
                width: 14px;
                height: 14px;
            }
            p{
                font-size: 14px;
                color: #000000;
                margin-left: 8px;
            }
        }
        .method_item.active{
            background: url('~images/pay_a.png') no-repeat;
            background-size: 100% 100%;
        }
        .pay_price{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            h3{
                font-size: 14px;
                color: #3C4658;
            }
            p{
                font-size: 16px;
                color: #FF9B00;
            }
        }
        .pay_info{
            display: flex;
            background: #F7F8FA;
            margin-top: 20px;
            width: 100%;
            img{
                width: 240px;
                height: 151px;
                object-fit: cover;
            }
            .right{
                padding:0 20px;
                width: 100%;

                .name{
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #12171F;
                }
                .info{
                    height: 54px;
                    font-size: 14px;
                    color: #12171F;
                    margin: 10px 0;
                    overflow: hidden;
                    overflow-y: auto;
                }
                .msg{
                    .speak{
                        margin: 10px 0;
                        font-size: 14px;
                        color: #12171F;
                    }
                    .detail{
                        font-size: 14px;
                        color: #12171F;
                        height: 40px;
                        overflow: hidden;
                        overflow-y: auto;
                    }
                }
                .price{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                   h4{
                        font-size: 14px;
                        font-weight: bold;
                        color: #12171F;
                   }
                   p{
                        font-size: 14px;
                        color: #12171F;
                   }
                }
            }
        }
        .pay_title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2{
                font-size: 16px;
                font-weight: bold;
                color: #12171F;
            }
            img{
                width: 16px;
                height: 16px;
            }
        }
    }
}