.live{
    .live_tab{
        position: fixed;
        top: 64px;
        left: 0;
        right: 0;
        z-index: 99;
        border-top:2px solid #EFF0F2;
    }
    .live_list{
        display: flex;
        flex-wrap: wrap;
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 20px;
        .live_item{
            width: 285px;
            height: 313px;
            background: #FFFFFF;
            margin-top: 20px;
            margin-right: 20px;
            .live_info{
                padding: 10px;
            }
            .bot{
                margin-top: 10px;
                font-size: 14px;
                color: #93979D;
            }
            .introduce{
                font-size: 14px;
                color: #93979D;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-top: 8px;
            }
            .speak{
                display: flex;
                margin-top: 10px;
                align-items: center;
                .name{
                    font-size: 14px;
                    font-weight: 500;
                    color: #3C4658;
                }
                .lable{
                    margin-left: 10px;
                    width: 56px;
                    height: 20px;
                    background: rgba(0,110,128,0.1);
                    border-radius: 4px;
                    border: 1px solid #006E80;
                    text-align: center;
                    line-height: 20px;
                    font-size: 12px;
                    color: #006E80;
                }
            }
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                h2{
                    width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 16px;
                    font-weight: bold;
                    color: #12171F;
                }
                p{
                    font-size: 14px;
                    font-weight: bold;
                    color: #FF9B00;
                }
            }
            .live_cover{
                width: 100%;
                height: 178px;
                position: relative;
                .img{
                    object-fit: cover;
                }
                .icon{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 58px;
                    height: 22px;
                }
            }
        }
        .live_item:nth-child(4n){
            margin-right: 0;
        }
    }
    
}