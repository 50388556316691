.login_bg{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .login_content{
        width: 680px;
        height: 394px;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
        border-radius: 4px;
        position: relative;
        display: flex;
        .other_btn{
            width: 320px;
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            .other_btn1{
                width: 150px;
                height: 46px;
                border-radius: 2px;
                border: 1px solid #DCDEE0;
                text-align: center;
                line-height: 46px;
                font-size: 18px;
                color: #3C4658;
                cursor: pointer;
                user-select: none;
            }
            .other_btn2{
                width: 150px;
                height: 46px;
                background: #006E80;
                border-radius: 2px;
                text-align: center;
                line-height: 46px;
                font-size: 18px;
                color: #FFFFFF;
                cursor: pointer;
                user-select: none;
            }
        }
        .login_content_left{
            width: 280px;
            height: 100%;
        }
        .login_close{
            width: 12px;
            height: 12px;
            position: absolute;
            right:15px;
            top: 12px;
        }
        .login_content_right{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .agrent{
                span{
                    color: #006E80;
                }
               .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #006E80;
                    border-color: #006E80;
               }
               .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner{
                    border-color: #006E80;
               }
               .ant-checkbox-checked:after{
                    border-color: 2px solid #006E80;
               }
                .agrent_p{
                    font-size: 12px;
                    color: #93979D;
                    user-select: none;
                    a{
                        color: #006E80;
                    }
                }
            }
            .other_login{
                width: 320px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title{
                    font-size: 12px;
                    color: #93979D;
                }
                .icon_list{
                    display: flex;
                    .icon_item{
                        position: relative;
                        width: 34px;
                        height: 34px;
                        margin-right: 54px;
                        cursor: pointer;
                        user-select: none;
                        p{
                            position: absolute;
                            bottom: -22px;
                            font-size: 12px;
                            color: #93979D;
                            left: 50%;
                            transform: translateX(-50%);
                            white-space: nowrap;
                        }
                    }
                    .icon_item:last-child{
                        margin-right: 0;
                    }
                }
            }
            .forget_reg{
                width: 320px;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                .left{
                    font-size: 12px;
                    color: #93979D;
                    user-select: none;
                    cursor: pointer;
                }
                .right{
                    font-size: 12px;
                    color: #006E80;
                    user-select: none;
                    cursor: pointer;
                }
            }
            .login_btn{
                width: 320px;
                height: 46px;
                background: #006E80;
                border-radius: 2px;
                text-align: center;
                line-height: 46px;
                font-size: 18px;
                font-weight: bold;
                color: #FFFFFF;
                cursor: pointer;
                user-select: none;
            }
            .input_item{
                display: flex;
                align-items: center;
                width: 320px;
                height: 46px;
                background: #FFFFFF;
                border-radius: 2px;
                border: 1px solid #DCDEE0;
                padding-left: 10px;
                padding-right: 20px;
                .img1{
                    width: 20px;
                    height: 20px;
                
                }
                input{
                    flex: 1;
                    margin-left: 10px;
                    height: 100%;
                }
                input::placeholder{
                    font-size: 14px;
                    color: #C2C6CE;
                }
                .img2{
                    width: 16px;
                    height: 16px;
                }
                p{
                    cursor: pointer;
                    user-select: none;
                    font-size: 14px;
                    color: #006E80;
                    white-space: nowrap;
                }
            }
            .mt20{
               margin-top: 20px; 
            }
        }
        
    }
}
.qrcode{
    height: 100%;
    width: 400px;
    iframe{
        width: 100% !important;
        height: 100% !important;
    }
}