.news_detail{
    background-color: #fff;
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    min-height: 80vh;
    padding: 0 150px;
    .title{
        font-size: 26px;
        font-weight: bold;
        color: #12171F;
        text-align: center;
        padding-top:30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #EBEDF0;
    }
    .time{
        font-size: 14px;
        color: #93979D;
        padding-top: 11px;
        padding-bottom: 30px;
        border-bottom: 1px solid #EBEDF0;
        text-align: center;
    }
    .content{
        padding-top: 30px;
        img{
            width: 50%;
        }
    }
}
