.forget{
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    height: 716px;
    .left{
        width: 360px;
    }
    .right{
        width: 100%;
        height: 100%;
        flex: 1;
        background-color: #fff;
        .title{
            font-size: 22px;
            font-weight: bold;
            color: #12171F;
            text-align: center;
            padding: 30px 0;
            border-bottom: 1px solid #EBEDF0;
        }
        .tips{
            width: 440px;
            margin-top: 10px;
            font-size: 12px;
            color: #93979D;
        }
        .login_btn{
            width: 440px;
            height: 46px;
            background: #006E80;
            border-radius: 2px;
            text-align: center;
            line-height: 46px;
            font-size: 18px;
            font-weight: bold;
            color: #FFFFFF;
            cursor: pointer;
            user-select: none;
        }
        .right_con{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .input_item{
            display: flex;
            align-items: center;
            width: 440px;
            height: 46px;
            background: #FFFFFF;
            border-radius: 2px;
            border: 1px solid #DCDEE0;
            padding-left: 10px;
            padding-right: 20px;
            .img1{
                width: 20px;
                height: 20px;
            
            }
            input{
                flex: 1;
                margin-left: 10px;
                height: 100%;
            }
            input::placeholder{
                font-size: 14px;
                color: #C2C6CE;
            }
            .img2{
                width: 16px;
                height: 16px;
            }
            p{
                cursor: pointer;
                user-select: none;
                font-size: 14px;
                color: #006E80;
            }
        }
        .mt30{
           margin-top: 30px; 
        }
    }
}