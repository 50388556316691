.nav{
    width: 100%;
    min-width: 1200px;
    height: 74px;
    background-color: #fff;
    position: fixed;
    z-index: 999;
    .logout_pop{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        .logout{
            width: 320px;
            height: 170px;
            background: #FFFFFF;
            box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
            .title{
                display: flex;
                margin-top: 32px;
                margin-left: 34px;
                display: flex;
                align-items: center;
                h2{
                    font-size: 16px;
                    font-weight: bold;
                    color: #12171F;
                    margin-left: 16px;
                }
            }
            .msg{
                font-size: 14px;
                color: #3C4658;
                margin-left: 72px;
                margin-top: 12px;
            }
            .logout_pop_btn{
                display: flex;
                justify-content: flex-end;
                margin-right: 32px;
                margin-top: 24px;
                .btn1{
                    width: 65px;
                    height: 32px;
                    background: #FFFFFF;
                    border: 1px solid #D9D9D9;
                    line-height: 32px;
                    text-align: center;
                    font-size: 14px;
                    color: #3C4658;
                    margin-right: 8px;
                    cursor: pointer;
                    user-select: none;
                }
                .btn2{
                    width: 65px;
                    height: 32px;
                    background: #006E80;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 32px;
                    text-align: center;
                    cursor: pointer;
                    user-select: none;
                }
            }
        }
    }
    .nav_con{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nav_con_left{
            display: flex;
            align-items: center;
        }
        .nav_con_right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .user_info{
            display: flex;
            align-items: center;
            margin-left: 19px;
            position: relative;
            .user_img{
                border-radius: 50%;
                overflow: hidden;
                width: 32px;
                height: 32px;
            }
            .user_info_pop{
                position: absolute;
                top: 36px;
                right: 0;
                width: 116px;
                height: 0;
                background: #FFFFFF;
                box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
                overflow: hidden;
                transition: height .3s ease-in;
                .user_info_pop_item:first-child{
                    margin-top: 22px;
                }
                .user_info_pop_item{
                    width: 116px;
                    text-align: center;
                    height: 36px;
                    line-height: 36px;
                    font-size: 14px;
                    color: #3C4658;
                }
                .userBor{
                    border-top:1px solid #E8E8E8;
                    padding: 8px 0;
                }
                .user_info_pop_item:hover{
                    color: #006E80;
                }
            }
            
            img{
                width: 32px;
                height: 32px;
            }
            .user_name{
                display: flex;
                align-items: center;
                p{
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                    font-size: 14px;
                    color: #333333;
                    margin-left: 8px;
                }
            }
            .user_bot{
                width: 10px;
                height: 10px;
                margin-left: 6px;
            }
            .user_info_name{
                font-size: 14px;
                color: #006E80;
                margin-left: 8px;
            }
        }
        .user_info_hover:hover .user_info_pop{
            height: 292px;
        }
        .nav_msg{
            position: relative;
            width: 32px;
            height: 32px;
            .count{
                min-width: 16px;
                height: 16px;
                font-size: 10px;
                color: #FFFFFF;
                position: absolute;
                top: -4px;
                right: -4px;
                background: #F5222D;
                border-radius: 50%;
                text-align: center;
                line-height: 16px;
            }
        }
        .nav_div{
            width: 1px;
            height: 16px;
            background-color: #C2C6CE;
            margin:0 30px;
        }
        .tab{
            display: flex;
            position: relative;
            .tab_item{
                margin-right: 39px;
                font-size: 16px;
                color: #3C4658;
                cursor: pointer;
            }
            .tab_item:nth-child(2){
                margin-right: 0;
            }
            .active {
                color: #006E80;
            }
            .bot{
                position: absolute;
                bottom: -10px;
                left: 26px;
                width: 16px;
                height: 4px;
                background: linear-gradient(270deg, #006E80 0%, rgba(0,110,128,0.7) 100%);
                border-radius: 50px 50px 50px 50px;
                transition: transform .3s;
            }
        }
        .logo{
            width: 201px;
            // height: 40px;
            cursor: pointer;
        }
        .search{
            margin-left: 30px;
            position: relative;
            .search_pop{
                position: absolute;
                width: 528px;
                height: 140px;
                background: #FFFFFF;
                box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.14);
                border-radius: 4px 4px 4px 4px;
                margin-top: 8px;
                padding: 18px 20px;
                .search_list{
                    display: flex;
                    flex-wrap: wrap;
                    overflow: hidden;
                    overflow-y: auto;
                    // height: 70px;
                    .search_item{
                        padding: 2px 12px;
                        font-size: 14px;
                        color: #93979D;
                        background: #F6F8F9;
                        border-radius: 30px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }
                }
                .search_history{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    p{
                        font-size: 14px;
                        color: #93979D;
                    }
                    img{
                        width: 18px;
                        height: 18px;
                        cursor: pointer;
                    }
                }
            }
            .search_input{
                border: 1px solid #F4F5F7;
                width: 530px;
                height: 40px;
                background: #F4F5F7;
               display: flex;
               align-items: center;
                border-radius: 4px;
                overflow: hidden;
                input{
                    width: 100%;
                    height: 100%;
                    background: #F4F5F7;
                    padding: 0 16px;
                    
                }
                .search_btn{
                    width: 72px;
                    height: 36px;
                    background: #006E80;
                    text-align: center;
                    line-height: 36px;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;
                    margin-right: 1px;
                }
            }
            .inputStu{
                border: 1px solid #006E80;
            }
        }
    }
}
