.menu{
    padding: 20px;
    background-color: #fff;
    .menu_item{
        width: 276px;
        height: 50px;
        display: flex;
        padding-left: 36px;
        align-items: center;
        img{
            width: 20px;
            height: 20px;
        }
        p{
            margin-left: 19px;
            font-size: 18px;
            color: #3C4658;
        }
    }
    .active {
        background: url('~images/my/menu_item.png') no-repeat;
        background-size:100% 100%;
        p{
            color: #fff;
        }
    }
}