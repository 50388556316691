.trtc_autoplay_mask{
    z-index: 999999 !important;
}
.barrage{
    width: 100%;
    height:200px;
    position: absolute !important;
    top: 0;
    z-index: 99999999999999999999999999999999999999999999;
    div{
        font-size: 16px;
        color: #FFFFFF;
        -webkit-text-stroke: 0.5px #000000;
    }
}

.endTips{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: #fff;
    font-size: 30px;
}
.video_handle{
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 9%, rgba(0,0,0,0.8) 62%);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    // filter: blur(30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    position: absolute;
    bottom: 0;
    z-index: 998;
    cursor: pointer;
    user-select: none;
    p{
        font-size: 12px;
        color: #FFFFFF;
    }
    .handle_list{
        display: flex;
        align-items: center;
      
        img{
            width: 20px;
            height: 20px;
            margin-right: 33px;
        }
    }
}
.home_detail{
    width: 1200px;
    margin: 0 auto;
    .video_map{
        width: 816px;
        height: 459px;
        background-color: #000;
        position: relative;
        overflow: hidden;
    }
    .video_map_a{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9999999999;
    }
    #trts_video{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        div{
            width: 100%;
            height: 100%;
             overflow: hidden;
        }
        video{
            width: 100% !important;
            height: 100% !important;
            object-fit: contain !important;
        }
    }
    .screen_video{
        width: 200px !important;
        height: 100px !important;
        position: absolute !important;
        right: 0;
        top: 0;
        z-index: 99;
        overflow: hidden;
        div{
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-color: red;
        }
         video{
            width: 100% !important;
            height: 100% !important;
            object-fit: contain !important;
        }
    }
    .home_detail_nav{
        height: 42px;
        line-height: 42px;
        font-size: 14px;
        color: #93979D;
        span{
            color: #12171F;
        }
    }
    .detail_info{
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        padding: 0 20px;
        padding-bottom: 20px;
        .detail_video{
            display: flex;
            .right{
                flex: 1;
                border: 1px solid #EDF0F2;
                .talk_input{
                    width: 312px;
                    height: 52px;
                    border-radius: 4px;
                    border: 1px solid #006E80;
                    margin-top: 10px;
                    margin-left: 16px;
                    display: flex;
                  
                    textarea{
                        padding: 8px 10px;
                        width: 100%;
                        height: 100%;
                        resize:none;
                        font-size: 14px;
                    }
                    textarea::placeholder{
                        font-size: 14px;
                        color: #C2C6CE;
                    }
                    .talk_send{
                        width: 52px;
                        height: 100%;
                        background: #006E80;
                        text-align: center;
                        line-height: 50px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #FFFFFF;
                    }
                }
                .talk_list{
                    height: 448px;
                    overflow: hidden;
                    overflow-y: auto;
                   padding: 20px 16px;
                   width: 100%;
                    .talk_list_item{
                        display: flex;
                        margin-bottom: 10px;
                        width: 100%;
                    }
                    .item_con{
                        // width: 100%;
                        display: flex;
                        flex-direction: column;
                        .item_info{
                            display: flex;
                            align-items: center;
                            .img{
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                                overflow: hidden;
                            }
                            img{
                                width: 32px;
                                height: 32px;
                            }
                            p{
                                font-size: 14px;
                                color: #12171F;
                                margin-left: 8px;
                            }
                        }
                        .talk_info{
                            display: inline-block;
                            max-width: 230px;
                            padding: 6px 10px;
                            background: #F7F8FA;
                            font-size: 14px;
                            font-weight: 500;
                            color: #12171F;
                            line-height: 16px;
                            margin-top: -4px;
                            margin-left: 35px;
                        }
                    }
                    .item_con1{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .item_info{
                            display: flex;
                            align-items: center;
                           
                            img{
                                width: 32px;
                                height: 32px;
                            }
                            p{
                                font-size: 14px;
                                color: #12171F;
                                margin-right: 8px;
                            }
                        }
                        .talk_info{
                            max-width: 230px;
                            padding: 6px 10px;
                            background: #F7F8FA;
                            font-size: 14px;
                            font-weight: 500;
                            color: #12171F;
                            line-height: 16px;
                            margin-top: -4px;
                            margin-right: 35px;
                        }
                    }
                }
                .des{
                    padding: 17px;
                    h2{
                        font-size: 16px;
                        font-weight: bold;
                        color: #000000;
                    }
                    .des_info {
                        font-size: 14px;
                        color: #3C4658;
                        line-height: 18px;
                        margin-top: 10px;
                    }
                }
                .catalogue{
                    padding: 15px 10px;
                    padding-bottom: 0;
                    overflow: hidden;
                    height: 460px;
                    overflow: auto;
                    .catalogue_item{
                        width: 100%;
                        height: 60px;
                        background: #F7F8FA;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        position: relative;
                        justify-content: space-between;
                        padding-right: 50px;
                        padding-left: 12px;
                        margin-bottom: 10px;
                        .left{
                            display: flex;
                            align-items: center;
                            .video_stop{
                                width: 17px;
                                height: 17px;
                            }
                            .num{
                                width: 35px;
                                text-align: center;
                            }
                        
                            .num,.name{
                                font-size: 14px;
                                color: #12171F;
                            }
                        }
                        .icon{
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 52px;
                            height: 24px;
                        }
                       .time{
                            color: #C2C6CE;
                            font-size: 14px;
                       }
                       .time.active{
                            color: #006E80;
                       }
                    }
                    .active{
                        background: #E5F0F2;
                    }
                }
                .right_tab{
                    height: 48px;
                    background: #F7F8FA;
                    display: flex;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    .right_tab_item{
                        font-size: 16px;
                        color: #3C4658;
                    }
                    .tab_item_bot{
                        position: absolute;
                        bottom: 0;
                        width: 16px;
                        height: 4px;
                        background: linear-gradient(270deg, #006E80 0%, rgba(0,110,128,0.7) 100%);
                        border-radius: 50px 50px 50px 50px;
                        transition: transform .3s;
                    }
                    .active{
                        font-weight: bold;
                        color: #006E80;
                    }
                    .right_tab_item{
                        margin-left: 39px;
                    }
                    .right_tab_item:nth-child(1) {
                        margin-left: 0;
                    }
                }
            }
            .left{
                .discuss{
                    height: 56px;
                    border: 1px solid #EDF0F2;
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    .speaker{
                        display: flex;
                        align-items: center;
                        .img{
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                        img{
                            width: 32px;
                            height: 32px;
                        }
                        p{
                            font-size: 14px;
                            color: #12171F;
                            margin-left: 10px;
                        }
                    }
                    .flow{
                        display: flex;
                        align-items: center;
                        .photo_list{
                            display: flex;
                            align-items: center;
                            margin-left: 30px;
                            .image{
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                overflow: hidden;
                            }
                            img{
                                width: 20px;
                                height: 20px;
                                // display: block;
                                
                            }
                            .image:nth-child(2) {
                                margin-left: -10px;
                            }
                            .image:nth-child(3) {
                                margin-left: -10px;
                            }
                        }
                        .flow_people{
                            font-size: 14px;
                            color: #93979D;
                            margin-left: 4px;
                        }
                        
                    }
                }
                .share_collect{
                    width: 816px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    padding-left: 30px;
                    background: #F7F8FA;
                    
                    position: relative;
                    .share_pop_bg{
                        position: fixed;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-color:transparent;
                        z-index: 999;
                    }
                    .share_pop{
                        position: absolute;
                        top: -215px;
                        left: -20px;
                        width: 282px;
                        height: 221px;
                        background: #FFFFFF;
                        box-shadow: 0px 2px 8px 0px rgba(200,201,204,0.5);
                        z-index: 9999;
                        h1{
                            font-size: 14px;
                            color: #12171F;
                            text-align: center;
                            margin-top: 20px;
                        }
                        .share_con{
                            padding: 20px;
                            display: flex;
                            flex-wrap: wrap;
                            padding-bottom: 0;
                            .share_btn{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                margin-right: 15px;
                                width: 70px;
                                justify-content: center;
                                margin-bottom: 20px;
                                img{
                                    width: 32px;
                                    height: 32px;
                                }
                                p{
                                    font-size: 14px;
                                    color: #93979D;
                                    margin-top: 8px;
                                }
                            }
                            .share_btn:nth-child(3n){
                                margin-right: 0;
                            }
                        }
                    }
                    .share_pop::after{
                        content: '';
                        display: inline-block;
                        border: 10px solid;
                        border-color:#fff transparent transparent transparent;
                        margin-left: 70px;
                    }
                    .click_icon{
                        display: flex;
                        align-items: center;
                        margin-right: 30px;
                        
                        img{
                            width: 20px;
                            height: 20px;
                        }
                        p{
                            font-size: 14px;
                            color: #3C4658;
                            margin-left: 8px;
                        }
                    }
                }
            }
           
        }
        .detail_header{
            display: flex;
            justify-content: space-between;
            height: 66px;
            align-items: center;
            
            h2{
                font-size: 24px;
                font-weight: bold;
                color: #12171F;
            }
            h3{
                font-size: 24px;
                font-weight: bold;
                color: #FF9B00;
            }
        }
    }
}