.logout_pop{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .logout{
        width: 320px;
        height: 170px;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.2);
        .title{
            display: flex;
            margin-top: 32px;
            margin-left: 34px;
            display: flex;
            align-items: center;
            h2{
                font-size: 16px;
                font-weight: bold;
                color: #12171F;
                margin-left: 16px;
            }
        }
        .msg{
            font-size: 14px;
            color: #3C4658;
            margin-left: 72px;
            margin-top: 12px;
        }
        .logout_pop_btn{
            display: flex;
            justify-content: flex-end;
            margin-right: 32px;
            margin-top: 24px;
            .btn1{
                width: 65px;
                height: 32px;
                background: #FFFFFF;
                border: 1px solid #D9D9D9;
                line-height: 32px;
                text-align: center;
                font-size: 14px;
                color: #3C4658;
                margin-right: 8px;
                cursor: pointer;
                user-select: none;
            }
            .btn2{
                width: 65px;
                height: 32px;
                background: #006E80;
                font-size: 14px;
                color: #FFFFFF;
                line-height: 32px;
                text-align: center;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}