.feedback{
    margin-top: 16px;
    margin-left: 32px;
    .feedback_btn{
        width: 86px;
        height: 42px;
        background: #006E80;
        border-radius: 2px;
        text-align: center;
        line-height: 42px;
        font-size: 16px;
        color: #FFFFFF;
        margin-left: 70px;
        margin-top: 24px;
    }
    .feedback_item{
        display: flex;
        p{
            font-size: 14px;
            color: #3C4658;
            margin-top: 6px;
        }
        textarea{
            width: 702px;
            height: 140px;
            background: #FFFFFF;
            border-radius: 2px;
            border: 1px solid #DCDEE0;
            resize:none;
            margin-left: 14px;
            padding: 6px 10px;
            font-size: 14px;
        }
        textarea::placeholder{
            font-size: 14px;
            color: #C2C6CE;
        }
    }
}

.change_pwd{
    display: flex;
    align-items: center;
    flex-direction: column;
    .tips{
        font-size: 12px;
        color: #93979D;
        margin-top: 10px;
        width: 440px;
    }
    .login_btn{
        width: 440px;
        height: 46px;
        background: #006E80;
        border-radius: 2px;
        text-align: center;
        line-height: 46px;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
        user-select: none;
        margin-top: 30px;
    }
    .input_item{
        display: flex;
        align-items: center;
        width: 440px;
        height: 46px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #DCDEE0;
        padding-left: 10px;
        padding-right: 20px;
        .img1{
            width: 20px;
            height: 20px;
        
        }
        input{
            flex: 1;
            margin-left: 10px;
            height: 100%;
        }
        input::placeholder{
            font-size: 14px;
            color: #C2C6CE;
        }
        .img2{
            width: 16px;
            height: 16px;
        }
        p{
            cursor: pointer;
            user-select: none;
            font-size: 14px;
            color: #006E80;
        }
    }
    .mt20{
       margin-top: 20px; 
    }
}
.my_order{
    // margin-top: 20px;
    .order_item{
        border: 1px solid #DCDEE0;
        margin-bottom: 20px;
        .order_bot{
            height: 40px;
            line-height: 40px;
            padding-left: 20px;
            display: flex;
            border-top: 1px solid #DCDEE0;
            p{
                font-size: 14px;
                color: #3C4658;
            }
            .time{
                margin-left: 16px;
                color: #12171F;
            }
        }
        .order_top{
            padding: 20px;
            padding-right: 83px;
            display: flex;
            align-items: center;
            img{
                width: 200px;
                height: 126px;
                object-fit: cover;
            }
            .i{
                width: 1px;
                height: 50px;
                background: #D9D9D9;
                margin-left: 30px;
                margin-right: 82px;
            }
            .price{
                font-size: 16px;
                color: #FF9B00;
            }
            .methed{
                font-size: 14px;
                color: #12171F;
                margin-left: 7px;
            }
            .info{
                margin-left: 20px;
                .p1{
                    font-size: 16px;
                    font-weight: bold;
                    color: #12171F;
                }
                .p2{
                    width: 242px;
                    height: 40px;
                    font-size: 14px;
                    color: #12171F;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2; /* 这里是超出几行省略 */
                    overflow: hidden;
                    display: -webkit-box;
                    margin-top: 10px;
                }
                .p3{
                    font-size: 14px;
                    color: #12171F;
                    margin-top: 8px;
                }
               
            }
        }
    }
}
.collect{
    display: flex;
    flex-wrap: wrap;
    padding-left: 22px;
    padding-top: 18px;
    .home_right_item{
        margin-right: 20px;
        margin-bottom: 20px;
    }
    .home_right_item:nth-child(3n) {
        margin-right: 0;
    }
}
.userinfo{
    padding-top: 16px;
    .ant-radio-checked .ant-radio-inner {
        background-color: #006E80;
        border-color: #006E80;
   }
   .ant-radio-wrapper{
        width: 80px;
   }
   .ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover .ant-radio-inner{
        border-color: #006E80;
   }
   .ant-radio-checked:after{
        border-color: 2px solid #006E80;
   }
   .user_item_img{
    display: flex;
    margin-bottom: 20px;
        p{
            width: 100px;
            text-align: right;
            font-size: 14px;
            color: #3C4658;
        }
        .upload {
            width: 120px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 14px;
            border: 1px dashed #ccc;
            position: relative;
            input{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
            }
            .img{
                width: 100%;
                height: 100%;
                position: relative;
            }
            h4{
                display: none;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                line-height: 120px;
                text-align: center;
                font-size: 14px;
                color: #FFFFFF;
                background-color: rgba(0,0,0,.5);
            }
            .Spin{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                line-height: 120px;
                text-align: center;
                background-color: rgba(255,255,255,.5);
            }
            
        }
        .upload:hover h4 {
            display: block;
        }
   }
   .user_btn{
        width: 86px;
        height: 42px;
        background: #006E80;
        border-radius: 2px;
        text-align: center;
        line-height: 42px;
        font-size: 16px;
        color: #FFFFFF;
        margin-left: 114px;
   }
   .user_item{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    p{
        width: 100px;
        text-align: right;
        font-size: 14px;
        color: #3C4658;
    }
    input{
        width: 200px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #DCDEE0;
        margin-left: 14px;
        font-size: 14px;
        padding:0 10px;
    }
    input::placeholder{
        font-size: 14px;
    }
    .radio{
        margin-left: 14px;
    }
   }
}
.my{
    .content{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        margin-top: 20px;
        .right{
            flex: 1;
            background-color: #fff;
            margin-left: 20px;
            border-radius: 4px;
            padding:30px;
            .title{
                font-size: 22px;
                color: #000000;
            }
        }
    }
    .header{
        width: 100%;
        min-width: 1200px;
        height: 220px;
        background: url('~images/my/my_bg.png') no-repeat;
        background-size: 100% 100%;
        .photo{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .img{
                width: 80px;
                height: 80px;
                border: 3px solid #A6D1CB;
                border-radius: 50%;
                overflow: hidden;
               
            }
            p{
                padding-top: 10px;
                font-size: 20px;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
    }
}
.tab_main{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.tab_list{
    width: 210px;
    height: 54px;
    background: #F7F8FA;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 5px;
    .tab_list_item{
        width: 100px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        color: #3C4658;
        cursor: pointer;
        user-select:none;
        text-align: center;
        position: relative;
        z-index: 99;
    }
    .active{
        color: #006E80;
    }
    .tab_list_item_bot{
        position: absolute;
        width: 100px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 40px;
        left: 5px;
        transition: transform .3s;
    }
}
.ant-cascader {
    width: 200px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #DCDEE0;
    margin-left: 14px;
    font-size: 14px;
    padding:0 10px;
    .ant-select-selector{
        padding: 0 !important;
        border: 0 !important;
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
        width: 100%;
        height: 100%;
    }
    .ant-select-selector:active{
        border: 0 !important;
        border-color: transparent !important;
        box-shadow: none !important;
        -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
    }
    .ant-select-selector:focus{
        border: 0 !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
    .ant-select-selector:hover{
        border: 0 !important;
        border-color: transparent !important;
        box-shadow: none !important;
    }
}
