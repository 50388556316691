.home_tab{
    width: 100%;
    min-width: 1200px;
    height: 64px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .tab_list{
        width: 390px;
        height: 54px;
        background: #F7F8FA;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 5px;
        .tab_list_item{
            width: 100px;
            height: 44px;
            line-height: 44px;
            font-size: 16px;
            color: #3C4658;
            cursor: pointer;
            user-select:none;
            text-align: center;
            position: relative;
            z-index: 99;
        }
        .active{
            color: #006E80;
        }
        .tab_list_item_bot{
            position: absolute;
            width: 100px;
            height: 44px;
            background: #FFFFFF;
            border-radius: 40px;
            left: 5px;
            transition: transform .3s;
        }
    }
}