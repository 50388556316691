.video{
    width: 816px;
    height: 459px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    video{
        width: 100%;
        height: 100%;
    }
}
.ant-modal-wrap{
    z-index: 99999999999999999999999999999999999999999999999999999999 !important;
}